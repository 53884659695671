<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search" :onFinish='pageSearchChange' layout='inline'>
        <a-form-item label="关键词" name='keywords'>
          <a-input v-model:value="search.keywords" style="width:250px" placeholder="请输入品牌商编号/名称/账号"></a-input>
        </a-form-item>
        <a-form-item label="审核状态" name='init_audit_status'>
          <a-select v-model:value="search.init_audit_status" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option :value="0">
              待审核
            </a-select-option>
            <a-select-option :value="1">
              审核通过
            </a-select-option>
            <a-select-option :value="-1">
              审核不通过
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="创建日期" name='time'>
          <a-range-picker v-model:value="time" valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit'>搜索</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data' class="tableFixBox" :scroll="{ x: 'max-content'}">
        <template v-slot:signing_time="{ record }">
          <p> {{record.start_time}}</p>
          <p> {{record.end_time}}</p>
        </template>
        <template v-slot:agent="{ record }">
          <p> {{record.agent_name}}</p>
        </template>
        <template v-slot:action="{ record }">
          <a-button type="primary" size="small" ghost v-has:brand_detail="()=> {$router.push('/brand/brand_list/detail?type=audit&id='+record.id)}" >详情</a-button>
          <a-button type="primary" size="small" class="m-l-20" v-if="record.init_audit_status==0" v-has:brandOpenReview="()=>{visible=true;brand_no=record.brand_no}" >审核</a-button>
        </template>
      </a-table>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
  </a-layout>
  <a-modal v-model:visible="visible" title="审核" centered @ok="handleOk(1)" @cancel='price = ""' :confirm-loading="confirmLoading">
    <a-radio-group v-model:value="init_audit_status" style="margin-bottom:20px">
      <a-radio value="1">
        审核通过
      </a-radio>
      <a-radio value="-1">
        审核不通过
      </a-radio>
    </a-radio-group>
    <a-textarea v-model:value="init_audit_remark" placeholder="审核不通过原因" :auto-size="{ minRows: 3, maxRows: 5 }" v-if="init_audit_status==-1" />
  </a-modal>
</template>
<script>
import { ref, onMounted, inject, reactive, toRefs, h } from "vue";
import { message } from "ant-design-vue";
import { getRouter } from "@/utils/app";
import { setPageState, getPageState } from "@/utils/pageState";
import { $iscode } from "@/utils/app";
import { getBrandtInitAuditLists, setBrandtInitAudit } from "@/api/brand";
// 初始化默认筛选项数值
let defSearch = {
  keywords: "",
  init_audit_status: "",
  page: 1,
  limit: 10,
  start_time: "",
  end_time: "",
};
export default {
  components: { },
  setup(props) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([]);
    const state = reactive({
      brandItem: {},
      listLoading: false,
      visible: false,
      total: 1,
      valueRadio: null,
      time: [],
      price: "",
      commission: "",
      confirmLoading: false,
      form: {},
      init_audit_status: "1",
      init_audit_remark: "",
      brand_no: "",
    });
    let columns = [
      {
        title: "公司名称",
        dataIndex: "company",
        key: "company",
      },
      {
        title: "品牌商名称",
        dataIndex: "brand_name",
        key: "brand_name",
      },
      {
        title: "品牌商账号",
        dataIndex: "account",
        key: "account",
      },
      {
        title: "品牌商编号",
        dataIndex: "brand_no",
        key: "brand_no",
      },
      {
        title: "所属代理商",
        dataIndex: "agent",
        key: "agent",
        slots: { customRender: "agent" },
      },
      {
        title: "状态",
        dataIndex: "init_audit_status_text",
        key: "init_audit_status_text",
      },
      {
        title: "创建时间",
        dataIndex: "create_time",
        key: "create_time",
      },
      {
        title: "操作",
        key: "action",
        fixed:"right",
        width:150,
        slots: { customRender: "action" },
      },
    ];

    const $router = inject("$router");
    const $Modal = inject("$Modal");

    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, size) => {
      search.value.page = 1;
      search.value.limit = size;
      initData(search.value);
    };
    const handleOk = (type) => {
      state.confirmLoading=true
      setBrandtInitAudit({
        brand_id: state.brand_no,
        init_audit_status: state.init_audit_status,
        init_audit_remark: state.init_audit_remark,
      })
        .then((res) => {
          if ($iscode(res.data, true)) {
            initData(search.value);
            state.visible = false;
            state.visibleC = false;
            state.price = "";
            state.commission = "";
          }
          state.confirmLoading = false;
        })
        .catch((error) => {
          $iscode(error, true);
          state.confirmLoading = false;
        });
    };
    // 时间筛选
    const onChange = (e) => {
      console.log(e);
      if (e.length > 0) {
        search.value.start_time = e[0];
        search.value.end_time = e[1];
      } else {
        search.value.start_time = "";
        search.value.end_time = "";
      }
    };
    const initData = async (values) => {
      state.listLoading = true;
      data.value = [];
      try {
        let res = await getBrandtInitAuditLists(values).then((res) => res.data);
        state.listLoading = false;
        if ($iscode(res)) {
          data.value = res.data.data;
          state.total = res.data.total;
        } else {
          message.error(res.message);
        }
      } catch (e) {
        state.listLoading = false;
      }
    };

    onMounted(() => {
      // 查看是否有留存状态，有则替换
      let pageState = getPageState(getRouter().route.path);
      if (pageState) {
        search.value = Object.assign(search.value, pageState);
      }
      initData(search.value);
    });
    return {
      ...toRefs(state),
      search,
      data,
      columns,
      pageSearchChange,
      pageCurrentChange,
      pageSizeChange,
      onChange,
      handleOk,
    };
  },
};
</script>
<style>
.tis {
  color: #f5222d;
}
</style>